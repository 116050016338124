import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    me: {
        textAlign: 'center'
    },
  }));
    

function GridImage(props) {
    const classes = useStyles();

    return (
        <Grid item xs={12} md={4} className={classes.me}>
            <img src={props.img} alt={props.imgAlt} className="center-crop" />
        </Grid>
    );
}

export default GridImage;