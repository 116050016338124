import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-scroll";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: '#284059'
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

function NavBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" component="h1" noWrap>
            Frank Holub
          </Typography>
          <Tabs centered>        
                <Link 
                    className="tabLink"
                    activeClass="active"
                    to="section1"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500} 
                    >
                    <Tab label='About' />
                </Link>
                <Link 
                    className="tabLink"
                    activeClass="active"
                    to="section2"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500} 
                    >
                    <Tab label='Projects' />
                </Link>
                <Link 
                    className="tabLink"
                    activeClass="active"
                    to="section3"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500} 
                    >
                    <Tab label='Contact' />
                </Link>
            </Tabs>  
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBar;