import React from 'react';
import Me from '../img/fholub-jp.jpg';
import Bio from '../content/bio.js';
import SectionHeader from './sectionHeader.js';
import CopyBox from './copybox';
import '../App.css';
    
function IntroCard() {

    return (
        <div className='whitespace'>
            <SectionHeader sectionNumber="section1" header="About Me"/>
            <CopyBox bodyText={Bio} img={Me} hasImage={true} imgAlt="Frank Holub"/>
        </div>
    );
}

export default IntroCard;