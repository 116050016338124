import React from 'react';
import 'typeface-roboto';
import './App.css';
import Container from '@material-ui/core/Container';
import NavBar from './components/navbar';
import IntroCard from './components/introcard';
import Projects from './components/projects';
import Footer from './components/footer';
import Contact from './components/contact';

function App() {
  return (
    <div>
    <NavBar />
    <Container maxWidth="md" className='projectContainer'>
      <IntroCard />
      <Projects />
      <Contact />
    </Container>
    <Footer />
  </div>
  ); 
}

export default App;
