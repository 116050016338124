import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import GitHubIcon from '@material-ui/icons/GitHub';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Typography } from '@material-ui/core';
//import ButtonBase from '@material-ui/core/ButtonBase';

function ContactIcon(props) {
        if (props.icon === 'Email') {
            return (
            <Grid item>
                <a href={props.link} className={'contactHover'}>
                    <EmailIcon className='iconPrimary' />
                </a>
                <div className={'centerText'}>
                    <Typography variant="button" component="h3"> 
                        Email
                    </Typography>
                </div>
            </Grid>
            );
        }
        else if (props.icon === 'Git') {
            return (
                <Grid item>
                    <a href={props.link} className={'contactHover'}>
                        <GitHubIcon className='iconPrimary' />
                    </a>
                    <div className={'centerText'}>
                        <Typography variant="button">
                            GitHub
                        </Typography>
                    </div>
                </Grid>
            );
        }
        else if (props.icon === 'Linkedin') {
            return (
                <Grid item>
                    <a href={props.link} className={'contactHover'}>
                        <LinkedInIcon className='iconPrimary' />
                    </a>
                    <div className={'centerText'}>
                        <Typography variant="button">
                            LinkedIn
                        </Typography>
                    </div>
                </Grid>
            );
        }
        else {
            return (
                <Grid item>
                    <a href={props.link} className={'contactHover'}><AccountCircleIcon /></a>
                </Grid>
            );
        }
}

export default ContactIcon;