import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GridImage from './gridImage.js';
import ContactIcon from './contacticon.js';

function CopyBox(props) {
    var hasImage = props.hasImage;
    var hasIcon =props.hasIcon
    if (hasImage === true && hasIcon === true) {
        return (
            <Paper elevation={3} style={{ padding: '4%' }}>
                <Grid 
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    container 
                    spacing={3} >
                    <Grid item xs={12} md={8}>
                        <Typography>
                            {props.bodyText} 
                        </Typography>
                    </Grid>
                    <GridImage img={props.img} imgAlt={props.imgAlt} />
                </Grid>
            </Paper>
        );
    }
    else if (hasIcon === true) {
        return (
            <Paper elevation={3} style={{ padding: '4%' }}>
                <Grid 
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    container 
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Typography>
                            {props.bodyText} 
                        </Typography>
                    </Grid>
                    <ContactIcon link='mailto:fholub13@gmail.com' icon='Email'/>
                    <ContactIcon link='https://www.linkedin.com/in/frank-holub-030867108/' icon='Linkedin'/>
                    <ContactIcon link='https://github.com/QuiteFrankly13' icon='Git'/>
                </Grid>
            </Paper>
        );
    }
    else if (hasImage === true) {
        return (
            <Paper elevation={3} style={{ padding: '4%' }}>
                <Grid 
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    container 
                    spacing={3} >
                    <Grid item xs={12} md={8}>
                        <Typography>
                            {props.bodyText} 
                        </Typography>
                    </Grid>
                    <GridImage img={props.img} imgAlt={props.imgAlt} />
                </Grid>
            </Paper>
        );
    }
    return (
        <Paper elevation={3} style={{ padding: '4%' }}>
            <Grid 
                direction="row"
                justify="flex-start"
                alignItems="center"
                container 
                spacing={3} >
                <Grid item xs={12}>
                    <Typography>
                        {props.bodyText} 
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default CopyBox;