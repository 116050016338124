import React from 'react';
import Typography from '@material-ui/core/Typography';
import '../App.css';

function SectionHeader(props) {
    return (
        <div id={props.sectionNumber}>
            <Typography variant="h4" component="h2" style={{ paddingTop: '3%', paddingBottom: '4%' }} className="under">
                {props.header}
            </Typography>
        </div>
    );
}

export default SectionHeader;