import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: '#284059'
    },
    text: {
        width: '100%',
        color: '#f2f2f2',  
        textAlign: 'center'    
    }
  }));

function Footer() {
    const classes = useStyles();

    return (
        <div>
            <AppBar position="static" className={classes.footer}>
                <Toolbar>
                    <Typography className={classes.text}>
                        <div className={'footerFlex'}>
                            <FingerprintIcon/> 
                            <p className={'footerFlexItem'}>
                                Frank Holub
                            </p> 
                            <p className={'footerFlexItem'}>
                                \\
                            </p> 
                            <div className={'footerFlexItem'}>
                                {(new Date().getFullYear())}
                            </div>
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Footer;