import React from 'react';
import SectionHeader from './sectionHeader.js';
import CopyBox from './copybox';
import ContactMe from '../content/contactMe.js';

function Contact() {
    return (
        <div className='whitespace'>
            <SectionHeader sectionNumber="section3" header="Contact"/>
            <CopyBox bodyText={ContactMe} hasIcon={true} />
        </div>
    );
}

export default Contact;