import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ProjectData from '../content/projectData.json' ;
//import ProjectModal from './projectModal.js';

function ProjectCard() {
  return ( 
    <div className="gridRoot">

      {ProjectData.map((projectDetail, index)=>{
        return (
          <Card variant="outlined" className="gridItem">
            <CardActionArea href={projectDetail.link}>
            <div className={'cardBody'}>
              <CardMedia
                component="img"
                alt={projectDetail.imgAlt}
                height="140"
                image={window.location.origin + projectDetail.imgURL}
                title={projectDetail.imgAlt}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h3">
                  {projectDetail.name}
                </Typography>
                <Typography variant="button" color="blue" component="p">
                  {projectDetail.type.join(' | ')}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {projectDetail.description}
                </Typography>
              </CardContent>
            </div>
            </CardActionArea>
          </Card>
        );
      })}

    </div>
  );
}

export default ProjectCard;
