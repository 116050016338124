import React from 'react';
import CopyBox from './copybox';
import SectionHeader from './sectionHeader.js';
import ProjectCard from './projectcard.js';
import projectsIntro from '../content/projectsIntro.js';

function Projects() {
    return (
        <div className='whitespace'>
            <SectionHeader sectionNumber="section2" header="Projects"/>
            <CopyBox bodyText={projectsIntro}/>
            <ProjectCard />
        </div>
    )
}

export default Projects; 